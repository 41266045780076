<template>
  <div class="flex-grow-1">
    <v-card max-width="650" class="shadow-lg mx-auto">
      <v-card-text>
        <div class="text-center pa-8">
          <v-icon size="65" color="success">mdi-check-circle-outline</v-icon>
          <h4 class="text-h4 font-weight-black mt-2">Well Done!!!</h4>
          <p class="body-1 font-weight-medium mt-5 mb-0">
            Your application has been sent to the school for consideration. You
            will be notified if the school wishes to offer you the role or if
            the job closes.
          </p>

          <v-btn 
            :to="{ name: 'MyAccount' }" 
            color="accent gradient"
            class="mt-5" 
          >
            Go to My Account
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AppliedJob",

  metaInfo: {
    title: "Applied Job",
  },

  created() {
    if (!this.$route.params.isApplied) {
      this.$router.push({ name: "Jobs" });
    }
  },
};
</script>